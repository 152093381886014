import {Serializer} from '@matchsource/api-utils';
import {GlStringHaploidPairModel, GlStringModel} from '@matchsource/models/hla';
import {haploidSerializer} from './haploid.serializer';
import {GlstringBoxDto} from '@matchsource/api-generated/subject';

export const glstringSerializer: Serializer<GlStringModel, GlstringBoxDto> = {
  fromDTO(input: GlstringBoxDto): GlStringModel {
    if (!input || !input.one || !input.two) {
      return {
        haploidPairs: [],
      };
    }

    const haploidPairs: GlStringHaploidPairModel[] = [];

    const haploidsNumber = Math.max(input.one.length, input.two.length);
    for (let i = 0; i < haploidsNumber; i += 1) {
      const one = haploidSerializer.fromDTO(input.one[i]);
      const two = haploidSerializer.fromDTO(input.two[i]);
      haploidPairs.push({one, two} as GlStringHaploidPairModel);
    }

    return {
      haploidPairs,
    };
  },
};
