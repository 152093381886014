import {Serializer} from '@matchsource/api-utils';
import {GlStringHaploidModel} from '@matchsource/models/hla';
import {HaploidDto} from '@matchsource/api-generated/subject';

export const haploidSerializer: Serializer<GlStringHaploidModel, HaploidDto> = {
  fromDTO(input: HaploidDto): GlStringHaploidModel {
    if (!input) {
      return {
        types: [],
        phasedTypes: [],
      };
    }

    return {
      types: input.types,
      phasedTypes: input.phasedTypes,
    };
  },
};
