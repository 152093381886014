import {HlaExtendedModel, HlaMethodLetter, TYPING_METHODS} from '@matchsource/models/hla';

/**********************************************************************************************
 * Match Grades Declaration
 **********************************************************************************************/
export enum MatchGrade {
  AlleleMatch = 'A',
  PotentialMatch = 'P',
  AlleleMismatch = 'L',
  AntigenMismatch = 'M',
}

/**********************************************************************************************
 * Match Grade Classes Declaration
 **********************************************************************************************/
const MATCH_GRADES_CLASS_MAP = {
  [MatchGrade.AlleleMatch]: 'allele-match',
  [MatchGrade.PotentialMatch]: 'potential-match',
  [MatchGrade.AlleleMismatch]: 'allele-mismatch',
  [MatchGrade.AntigenMismatch]: 'antigen-mismatch',
};

export const mapLeaderTyping = (hlaModel: HlaExtendedModel): string => {
  if (hlaModel.leaderValue1 && hlaModel.leaderValue2) {
    return `${hlaModel.leaderValue1}${hlaModel.leaderValue2}`;
  }
  if (hlaModel.leaderValue1) {
    return `${hlaModel.leaderValue1}${hlaModel.leaderValue1}`;
  }
  if (hlaModel.leaderValue2) {
    return `${hlaModel.leaderValue2}${hlaModel.leaderValue2}`;
  }
  return '';
};

export const getMatchGradeClass = (matchGrade: string) => (matchGrade ? MATCH_GRADES_CLASS_MAP[matchGrade] : '');

export const formatType = (params: {value: string; typingMethod: string; hlaExprChar: string}): string => {
  return params.value && params.typingMethod === TYPING_METHODS.SER
    ? `${params.value}`
    : (params.value || '') + (params.hlaExprChar || '');
};

export const formatMethodLetter = (method: string): HlaMethodLetter => {
  if (!method) {
    return '';
  }
  return TYPING_METHODS.SER === method ? 's' : 'd';
};

type LeaderFields = 'matchGrade1' | 'matchGrade2' | 'leaderValue1' | 'leaderValue2';

type LeaderValues = Pick<HlaExtendedModel, LeaderFields>;

export const formatLeaderValues = <T extends LeaderValues>(
  hlaData: T
): {leaderValue1Formatted: string; leaderValue2Formatted: string} => {
  const mismatch1 =
    hlaData.matchGrade1 === MatchGrade.AlleleMismatch || hlaData.matchGrade1 === MatchGrade.AntigenMismatch;
  const mismatch2 =
    hlaData.matchGrade2 === MatchGrade.AlleleMismatch || hlaData.matchGrade2 === MatchGrade.AntigenMismatch;
  const singleMismatch = (mismatch1 && !mismatch2) || (!mismatch1 && mismatch2);

  return {
    leaderValue1Formatted: hlaData.leaderValue1 && singleMismatch ? `(${hlaData.leaderValue1})` : '',
    leaderValue2Formatted: hlaData.leaderValue2 && singleMismatch ? `(${hlaData.leaderValue2})` : '',
  };
};
